@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-anchor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-input-number-arrows::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-input-number-arrows[type="number"] {
  -moz-appearance: textfield;
}

#scanner-container > video {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

#scanner-container > canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.infinite-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.infinite-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 2px;
}

/* Handle */
.infinite-scroll::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 2px;
}

/* Handle on hover */
.infinite-scroll::-webkit-scrollbar-thumb:hover {
  background: #555; 
}